<template>
    <v-container fuild>
        <v-row>
            <v-col cols="12" md="10" offset-md="1">
                <v-text-field
                    v-model="search"
                    :placeholder="$t('packs.search')"
                    solo
                    clearable
                    dense
                    rounded
                    prepend-inner-icon="mdi-magnify"
                ></v-text-field>
            </v-col>
        </v-row>

        <v-row v-if="profile.security.packsCreate">
            <v-col>
                <v-toolbar flat dense color="transparent">
                    <v-spacer />
                    <v-dialog v-model="dialogCreate" max-width="500px" persistent>
                        <template v-slot:activator="{ on }">
                            <v-btn color="primary" dark v-on="on">
                                {{ $t('buttons.createPack') }}
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-title>
                                <span class="headline blue--text text--darken-1">{{ $t('title.newPack') }}</span>
                            </v-card-title>

                            <v-card-text>
                                <v-text-field
                                    v-model="creatingPack.name"
                                    :counter="50"
                                    :label="$t('packs.attributes.name')"
                                    required
                                    color="red"
                                ></v-text-field>

                                <v-text-field
                                    v-model="creatingPack.label"
                                    :counter="50"
                                    :label="$t('packs.attributes.label')"
                                    required
                                    color="red"
                                ></v-text-field>
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="red darken-1" text @click="cancelCreate">
                                    {{ $t('buttons.cancel') }}
                                </v-btn>
                                <v-btn color="blue darken-1" text @click="create">
                                    {{ $t('buttons.save') }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" md="4" v-for="(pack, index) in packsToDisplay" :key="index">
                <v-card class="mb-4">
                    <v-card-title>
                        <span>{{ pack.label ? pack.label : pack.name }}</span>
                    </v-card-title>
                    <v-card-subtitle v-if="pack.nightly">
                        <v-chip small color="yellow darken-2" dark>{{ $t('packs.attributes.nightly') }}</v-chip>
                    </v-card-subtitle>

                    <v-card-text>
                        <pack-description :pack="pack" :allow-editing="false" />
                    </v-card-text>

                    <v-card-actions>
                        <v-btn color="primary" text small @click="openPack(pack)">
                            <v-icon left>mdi-folder-open</v-icon>
                            {{ $t('buttons.packOpen') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import OrderBy from 'lodash/orderBy';
import packDescription from '@/components/packs/description';

export default {
    name: 'packs',
    data: () => ({
        dialogCreate: false,
        creatingPack: {
            name: undefined,
            label: undefined,
        },
        search: undefined,
    }),
    created() {
        this.fetchPacks();
    },
    components: {
        packDescription,
    },
    computed: {
        ...mapGetters(['packs', 'profile']),
        packsToDisplay() {
            const filterPacks = this.packs.filter((p) => {
                if (this.search) {
                    return p.label.toLowerCase().includes(this.search.toLowerCase());
                }
                return true;
            });

            return OrderBy(filterPacks, ['label'], ['asc']);
        },
    },
    methods: {
        ...mapActions(['findPacks', 'createPack']),
        cancelCreate() {
            this.dialogCreate = false;
            this.creatingPack = {
                name: undefined,
                label: undefined,
            };
        },
        create() {
            this.createPack(this.creatingPack);
            this.cancelCreate();
        },
        fetchPacks() {
            this.findPacks();
        },
        openPack(pack) {
            this.$router.push({ name: 'pack', params: { pack_id: pack.id } });
        },
    },
};
</script>

<style></style>
